import React, { useEffect, useState } from "react";
import logo from "../../assets/Logos/shukran-logo-with-tagline-light.png";
import { useForm } from "react-hook-form";
import loader from "../../assets/Icons/Registration/loader.svg";
import NotFoundIcon from "../../assets/Icons/Rating/not_found.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../../state/index";
import { Link, Outlet } from "react-router-dom";
import { format254No, testUrl } from "../../helper";
import { saveToSessionStorage } from "../../Services/Storage";
import { loginUser } from "../../state/actions/registerUser";
import {
  joinSacco,
  requestOtpCode,
} from "../../state/reducers/user/user.thunk";
import checkCircle from "../../assets/Icons/Popups/check_circle.svg";
import eye from "../../assets/Icons/Dashboard/eye_open.svg";
import eyeClosed from "../../assets/Icons/Registration/eye_closed.svg";
import { balanceActions } from "../../state/reducers/user/user.actions";
import { resetActions } from "../../state/reducers/reset/reset.actions";
import useIsIOS from "../popups/useIsIos";

const axios = require("axios");

export default function Registration() {
  // set state for input values
  const [results, setResults] = useState("created");
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [isIOS, setIsIOS] = useIsIOS();
  const [acceptTerms, setAcceptTerms] = useState(0);
  const [referrer, setReferrer] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  // gets data from redux store
  const dispatch = useDispatch();
  const [regParams] = useSearchParams();

  // updates data to redux store

  const { registerUser, registerToken } = bindActionCreators(actions, dispatch);
  const balanceState = useSelector((state) => {
    return state.balance;
  });
  const profileState = useSelector((state) => state.profile);
  const { userType } = balanceState;
  // query strings
  const { addTipAmount, addReferrerId } = bindActionCreators(actions, dispatch);
  const [searchParams] = useSearchParams();

  // values from react hook
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
  } = useForm();
  let phoneNumberForm = searchParams.get("phone");

  useEffect(() => {
    let phoneNub = searchParams.get("phone");
    let referrer = searchParams.get("referrer");
    setReferrer(referrer);
    console.log("search param", referrer);
    if (referrer || searchParams.get("phone")?.length > 0) {
      setValue("phoneNumber", referrer);
      setValue("phoneNumber", searchParams.get("phone") || "");
    }
    return () => {};
  }, []);

  // navigate function
  let navigate = useNavigate();

  // set method that handles input change event
  const handleInputChange = (e, setFunc) => {
    const value = e.target.value;
    setFunc(value);
  };
  const handleUpper = (STRing) => {
    let strWrd = STRing ? STRing : "SERVICE WORKER";
    return strWrd.toUpperCase();
  };

  // puts state into object
  const getNonShukranData = (data) => {
    return {
      first_name: data["firstName"],
      last_name: data["lastName"],
      phone: format254No(data["phoneNumber"]),
      password: data["password"],
      is_sacco: true,
      sacco_type: "new",
      user_type: "SERVICE WORKER",
      platform: isIOS.platform ? isIOS.platform : "",
      device: isIOS.device ? isIOS.device : "",
    };
  };
  const getData = (data) => {
    return {
      first_name: data["firstName"],
      last_name: data["lastName"],
      phone: data["phoneNumber"],
      password: data["password"],
      is_sacco: handleUpper(userType) === "SERVICE WORKER" ? false : true,
      sacco_type: handleUpper(userType),
      user_type: handleUpper(userType),
      platform: isIOS.platform ? isIOS.platform : "",
      device: isIOS.device ? isIOS.device : "",
    };
  };

  const getQueryUserData = (data) => {
    return {
      first_name: data["firstName"],
      last_name: data["lastName"],
      phone: data["phoneNumber"],
      password: data["password"],
      is_sacco: true,
      sacco_type: "new",
      user_type: "SERVICE WORKER",
      platform: isIOS.platform ? isIOS.platform : "",
      device: isIOS.device ? isIOS.device : "",
    };
  };

  // set method that runs when the submit is hit
  const handleFormSubmit = async (data) => {
    if (data["password"] !== data["confirmPassword"]) {
      console.log("error field", "password");
      setError("confirmPassword", {
        message: `Ensure the submitted passwords match`,
      });
    } else {
      let nonShukranId = searchParams.get("phone");
      let queryUserType = searchParams.get("type");
      let regData = nonShukranId
        ? getNonShukranData(data)
        : queryUserType
        ? getQueryUserData(data)
        : getData(data);
      console.log("Registration", regData);

      let url = nonShukranId
        ? `${testUrl}accounts/auth/signup/`
        : `${testUrl}accounts/auth/signup/`;
      try {
        setIsLoading(true);
        const response = await axios({
          url: url,
          method: "post",
          data: regData,
          validateStatus: function (status) {
            return status >= 200 && status < 500;
          },
        });

        if (response["status"] === 201) {
          addReferrerId({ referrerId: referrer });
          localStorage.setItem("referrer", referrer);
          await saveToSessionStorage("token", response.data.data.token);
          await saveToSessionStorage(
            "ShukranId",
            response?.data?.data?.shukran_id
          );
          await saveToSessionStorage(
            "firstName",
            response.data.data.first_name
          );

          setResults("created");
          setIsError(false);
          setIsLoading(false);
          registerUser(response.data.data);
          loginUser(response.data.data);
          dispatch(balanceActions.balanceLoadError(""));
          localStorage.setItem("isLoggedIn", true);
          let params = {
            phone: response.data.data.phone,
            token: response.data.data.token,
          };
          dispatch(requestOtpCode(params, response.data.data.token));
          navigate("/confirmcode", {
            state: {
              sacco_account: searchParams.get("phone")?.length > 0,
            },
          });

          // navigate("/");
        } else if (response["status"] >= 400) {
          console.log(response);
          setResults(response.data);
          setIsError(true);
          setIsLoading(false);
        } else if (response.response.status >= 400) {
          console.log(response);
          setResults(response.response.data);
          setIsError(true);
          setIsLoading(false);
        } else {
        }
      } catch (e) {
        console.log("500 response", e.response);
        if (e?.response?.status === 500) {
          setIsError(true);
          setResults({
            msg: e.response.data.msg,
          });
          setIsLoading(false);
        } else if (e?.request) {
          setIsError(true);
          setResults({
            msg: "Server response timeout. please try again shortly",
          });
          setIsLoading(false);
        }
      }
    }
  };

  const SuccessRating = () => (
    <div className="  my-auto p-7 shadow bg-white pt-10   flex flex-col relative pb-4 rounded-3xl z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(resetActions.updateProfileSuccess({}));
        }}
      >
        X
      </button>

      <img src={checkCircle} alt="error-img" className="h-20" />

      <p className="justify-self-center	text-lg text-center py-2">
        {profileState.results.msg}
      </p>
    </div>
  );

  const RegisterError = () => (
    <div className="p-7 shadow bg-white pt-10 flex flex-col relative pb-4 rounded-3xl z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          setIsError(false);
          // navigate("/rate");
        }}
      >
        X
      </button>
      <img src={NotFoundIcon} alt="error-img" className="h-20" />
      <h2 className={"text-center font-bold	text-lg"}>Sign up error</h2>
      <p className="justify-self-center	text-base text-center py-2">
        {results?.msg}
      </p>
      {results?.token && (
        <button
          className="rounded-full py-2  bg-[#F2C773] text-white text-base w-full mt-4"
          onClick={() => {
            setIsError(false);
            registerToken(results?.token);
            navigate("/confirmCode");
          }}
        >
          {" "}
          Submit OTP code
        </button>
      )}
    </div>
  );

  return (
    <div
      className={`
      ${isLoading ? "relative" : ""}
      ${isError ? "relative" : ""} 
      w-full flex flex-col`}
    >
      <div
        className={`
          ${isLoading ? "blur-sm" : ""}
          ${isError ? "blur-sm" : ""}
          flex w-fit flex-col text-black mx-auto bg-imag-dash`}
      >
        <div className="header flex flex-row justify-center mt-2">
          {/* <Link to="/" className="underline"> */}
          <a
            href="https://shukran.co/"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            <img
              src={logo}
              alt="logo of shukran"
              className="rounded py-3 h-16 mt-16 mb-4"
            />
          </a>
        </div>
        <div className="input-div flex flex-col	pt-4 bg-white rounded-t-3xl px-8 ">
          <h2 className="text-lg">Create your Shukran account</h2>
          <form
            className="flex flex-col"
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <div className="flex flex-row mt-2">
              <div className="w-1/2 mr-2 ">
                <label className="mt-2 text-[#929FB1] text-sm italic">
                  First Name
                </label>
                <input
                  className=" border-solid border-b-2 focus:border-b-[#50a3a7]/70 outline-none h-12 placeholder:text-base w-full py-2 text-black"
                  type="text"
                  placeholder="Enter First Name"
                  autoFocus
                  {...register("firstName", {
                    required: true,
                  })}
                />
                {errors.firstName && (
                  <p className="text-red-600 text-sm uppercase">
                    First Name required
                  </p>
                )}
              </div>
              <div className="w-1/2">
                <label className="mt-2 text-[#929FB1] text-sm italic">
                  Last Name{" "}
                </label>
                <input
                  className=" border-solid border-b-2 focus:border-b-[#50a3a7]/70 outline-none h-12 placeholder:text-base  w-full py-2 text-black"
                  type="text"
                  placeholder="Enter Last Name"
                  {...register("lastName", {
                    required: true,
                  })}
                />
                {errors.lastName && (
                  <p className="text-red-600 text-sm uppercase">
                    Last Name required
                  </p>
                )}
              </div>
            </div>
            <div className="flex flex-col mt-2">
              <label className="mt-2 text-[#929FB1] text-sm italic">
                Phone Number{" "}
              </label>
              <input
                //value of params from url

                className="border-solid border-b-2 focus:border-b-[#50a3a7]/70 outline-none h-12placeholder:text-base  w-full py-2 text-black"
                type="number"
                placeholder="Enter Phone Number"
                {...register("phoneNumber", {
                  required: true,
                  // maxLength: 15,
                  // pattern:
                  //   /^(?:254|\+254|0)?((?:(?:7(?:(?:[01249][0-9])|(?:5[789])|(?:6[89])))|(?:1(?:[1][0-5])))[0-9]{6})$/i,
                })}
              />
              {errors.phoneNumber && (
                <p className="text-red-600 text-sm uppercase">
                  Please enter a valid safaricom number
                </p>
              )}
            </div>
            <div className="flex flex-col mt-2">
              <label className="mt-2 text-[#929FB1] text-sm italic">
                Password{" "}
              </label>
              <div className="flex flex-row items-center">
                <input
                  className="border-solid border-b-2 focus:border-b-[#50a3a7]/70 outline-none h-12 w-full py-2 text-black placeholder:text-base"
                  type={showPass ? "text" : "password"}
                  placeholder={showPass ? "pass123" : "*******"}
                  {...register("password", {
                    required: true,
                  })}
                />
                <div className="-ml-6" onClick={() => setShowPass(!showPass)}>
                  <img
                    src={showPass ? eye : eyeClosed}
                    alt="eye"
                    className="h-6"
                  />
                </div>
              </div>
              {errors.password && (
                <p className="text-red-600 text-sm uppercase">
                  password required
                </p>
              )}
            </div>
            <div className="flex flex-col mt-2">
              <label className="mt-2 text-[#929FB1] text-sm italic">
                Confirm Password{" "}
              </label>

              <div className="flex flex-row items-center">
                <input
                  className="border-solid border-b-2 focus:border-b-[#50a3a7]/70 outline-none h-12 w-full py-2 text-black placeholder:text-base"
                  type={showConfirmPass ? "text" : "password"}
                  placeholder={showConfirmPass ? "pass123" : "*******"}
                  {...register("confirmPassword", {
                    required: {
                      value: true,
                      message: "Confirm password required",
                    },
                  })}
                />
                <div
                  className="-ml-6"
                  onClick={() => setShowConfirmPass(!showConfirmPass)}
                >
                  <img
                    src={showConfirmPass ? eye : eyeClosed}
                    alt="eye"
                    className="h-6"
                  />
                </div>
              </div>
              {errors.confirmPassword && (
                <p className="text-red-600 text-sm uppercase">
                  {errors.confirmPassword?.message}
                </p>
              )}
              <div className="flex flex-row items-center mt-5">
                <input
                  className="border-solid border-b-2 focus:border-b-[#50a3a7]/70 outline-none h-5 w-4 py-2 text-black placeholder:text-base"
                  type={"checkbox"}
                  required={true}
                  value={acceptTerms}
                  onClick={() => {
                    if (acceptTerms === 0) {
                      setAcceptTerms(1);
                    } else if (acceptTerms === 1) {
                      setAcceptTerms(0);
                    }
                    console.log(acceptTerms, "e");
                  }}
                  {...register("confirm", {
                    required: {
                      value: true,
                      message: "Confirm password required",
                    },
                  })}
                />
                <div
                  className="ml-4"
                  // onClick={() => {
                  //   window.open(
                  //     "https://www.shukransacco.com/privacy/",
                  //     "_blank"
                  //   );
                  //   window.open(
                  //     "https://www.shukransacco.com/terms/",
                  //     "_blank"
                  //   );
                  // }}
                >
                  <p style={{ fontSize: "14px" }}>
                    I agree to{" "}
                    <a
                      style={{ textDecoration: "underline" }}
                      href="https://shukran.co/terms/"
                      target="_blank"
                    >
                      terms of use
                    </a>{" "}
                    and{" "}
                    <a
                      style={{ textDecoration: "underline" }}
                      href="https://shukran.co/policy/"
                      target="_blank"
                    >
                      privacy policy
                    </a>
                  </p>
                </div>
              </div>
              {/* <div className="flex flex-row items-center mt-5">
                <input
                  className="border-solid border-b-2 focus:border-b-[#50a3a7]/70 outline-none h-5 w-4 py-2 text-black placeholder:text-base"
                  type={"checkbox"}
                  {...register("confirm", {
                    required: {
                      value: true,
                      message: "Confirm password required",
                    },
                  })}
                />
                <p>Agree to our privacy policy</p>
              </div> */}
            </div>
            <button
              className="rounded-full py-2 bg-[#F2C773] text-white text-base w-full mt-8"
              type="submit"
            >
              {" "}
              SIGN UP
            </button>
          </form>
        </div>

        <div className="footer-reg flex flex-col bg-white px-8 pt-12">
          <p>
            <Link to="/login" className="underline">
              Have an account?
            </Link>{" "}
            <Link to="/login" className="underline ml-3 text-[#6CCBD1]">
              Login
            </Link>
            <Outlet />
          </p>
        </div>
      </div>
      <div
        className={`${
          isError ? "absolute blur-none" : "hidden"
        } flex justify-center items-center flex-col w-full h-full`}
      >
        <RegisterError />
      </div>
      <div
        className={`${
          profileState.results.success ? "absolute blur-none" : "hidden"
        } flex flex-col justify-center items-center w-4/5 h-full`}
      >
        <SuccessRating />
      </div>
      <div
        className={`
          ${isLoading ? "absolute blur-none" : "hidden"}
          flex flex-col justify-center items-center w-full h-full
        `}
      >
        <div className="my-auto p-7 shadow bg-white pt-10 flex flex-col relative pb-4 rounded-3xl z-50">
          <img
            src={loader}
            alt="loader-img"
            className="rounded px-8 mx-auto h-28 animate-spin"
          />
          <p className="text-xl mt-4 p-4 text-center">
            Getting you signed up !
          </p>
        </div>
      </div>
    </div>
  );
}
